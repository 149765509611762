import React from 'react'
import AllProjects from './AllProjects'

const Projects = () => {
  return (
    <>
    <div className='projects_heading'>PROJECTS</div>
    <AllProjects/>
    </>
  )
}

export default Projects